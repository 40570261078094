import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./Products.scss";
import DashBoardTable from "../../../Components/Dashboard_Table/DashboardTable";
import MobileTable from "../../../Components/Mobile_Table/MobileTable";
import MobileMenuBar from "../../../Components/Mobile_Menubar/MobileMenubar";
import { useMutation, useQuery } from "@apollo/client";
import { productPageQuery } from "../../../Operations/Queries";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { productsMutation } from "../../../Operations/Mutations";
import CancelIcon from "@mui/icons-material/Cancel";
import Toaster from "../../../Components/ToastMessage";
import { toast } from "react-toastify";
import { CountryContext } from "../../..";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { settingsPageQuery } from "../../../Operations/Queries";
import { useNavigate } from "react-router-dom";
import * as mutations from "../../../Operations/Mutations";
import { ApolloError } from "@apollo/client/errors";
import { onError } from "apollo-link-error";
import NoProductFound from "../../../Components/Noproduct/NoProductFound";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
//import { ServerResponse } from 'http';
//import { onError } from "@apollo/client/link/error";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// interface ProductImageInterface {
//   name: string;
//   size: number;
//   progress: number;
// }

interface searchProduct {
  sku: string;
  status: string;
  name: string;
  price: number;
  image: string;
  qty: number;
  weight: number;
  ean: string;
  image_gallery: { url: string }[];
}

interface Data {
  [key: string]: any;
}

interface UploadedFile {
  name: string;
  base64String: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//   },
// }));

const Product = () => {
  const [value, setValue] = useState(0);
  const [selectedMenuItem, setSelectedMenuItem] =
    useState("Create New Product");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchProductTerm, setSearchProductTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState<searchProduct | null>(
    null
  );
  const [updatedPrice, setUpdatedPrice] = useState(
    selectedProduct?.price || ""
  );
  const [updatedQuantity, setUpdatedQuantity] = useState(
    selectedProduct?.qty || ""
  );
  const [searchResultModalShow, setSearchResultModalShow] = useState(true);
  const [editPriceTableData, setEditPriceTableData] = useState<any[]>([]);
  const [updatedRows, setUpdatedRows] = useState<Data[]>([]);
  const [totalPage, setTotalPage] = useState<any>();
  const [selectedPage, setSelectedPage] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [bulkProductVariables, setBulkProductVariables] = useState({
    pageSize: 300,
    currentPage: 1,
    type: "",
    downloadAll: "true",
  });
  const [dataforNewProduct, setDataForNewProduct] = useState({
    name: "",
    sku: "",
    price: "",
    qty: "",
    weight: "",
    ean: "",
    shortDescription: "",
    description: "",
    images: [
      {
        name: "",
        base64_encoded_file: "",
      },
      {
        name: "",
        base64_encoded_file: "",
      },
    ],
  });
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [bulkUploadStr, setBulkUploadStr] = useState("");
  const [bulkUpdateStr, setBulkUpdateStr] = useState("");

  const loggedInToken = localStorage.getItem("loggedInCust");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const InputRefForBulkUpdate = useRef<HTMLInputElement>(null);
  const { t }: { t: (key: string) => string } = useTranslation();
  const { store } = useContext(CountryContext);
  const currencyMap : any  = {
    sell_in: { en: "INR", ar: "روبية هندية" },
    sell_sa: { en: "SAR", ar: "ريال سعودي" },
    sell_om: { en: "OMR", ar: "ريال عماني" },
    seller: { en: "AED", ar: "درهم" },
  };
  
  const defaultCurrency = { en: "AED", ar: "درهم" };
  
  const { en: currEn, ar: currAr } = currencyMap[store] || defaultCurrency;
  
  const currency = i18n.language === "ar" ? currAr : currEn;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const { data: searchProductData } = useQuery(
    productPageQuery.SEARCH_PRODUCT,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
        onerror(error: any) {
          console.error("error 00: ", error);
        },
      },
      variables: {
        searchByName: searchTerm,
        pageSize: 20,
        currentPage: 1,
      },
    }
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchProductTerm.length >= 2) {
        setDebouncedSearchTerm(searchProductTerm);
      }
    }, 300);
    return () => clearTimeout(handler);
  }, [searchProductTerm]);

  const {
    data: editProductData,
    refetch: editProductDataRefetch,
    error: editProductDataError,
    loading: editProductDataLoading,
  } = useQuery(productPageQuery.EDIT_PRICE_QUANTITY_LIST, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
      onerror(error: any) {
        console.error("error 10: ", error);
      },
    },
    variables: {
      pageSize: 10,
      searchProductTerm: debouncedSearchTerm,
      currentPage: currentPage,
      type: "seller_products",
    },
  });
  const EditPriceProductData = editProductData?.sellerProductsList[0]?.items;

  const errorEditProductData = editProductDataError?.message;
  const [revokeCustomertoken] = useMutation(
    mutations.logoutMutation.REVOKE_CUSTOMER_TOKEN,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );
  const handleLogOut = async () => {
    try {
      await revokeCustomertoken();
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("preferredLanguage");
      navigate("/");
    } catch (error) {
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("preferredLanguage");
      navigate("/");
    }
  };
  const { data: SettingsMainData } = useQuery(
    settingsPageQuery.SETTINGS_MAINPAGE,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
        onerror(error: any) {
          handleLogOut();
        },
      },
    }
  );
  if (
    errorEditProductData === "Response not successful: Received status code 401"
  ) {
    const settingsData = SettingsMainData?.sellerData;
    if (settingsData === undefined) handleLogOut();
  }

  const [addProduct] = useMutation(productsMutation.ADD_PRODUCT, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const [
    updateProduct,
    { loading: updateProductDataLoading, error: updateProductDataError },
  ] = useMutation(productsMutation.UPDATE_PRODUCT, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const [createProduct, { error: createProductError }] = useMutation(
    productsMutation.CREATE_PRODUCT,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
      onError: (error: ApolloError, success) => {
        console.error("createProductError 101: ", error.networkError);
      },
      onCompleted: (data: any) => {
        console.error("createProductError 2 completed: ", data);
      },
    }
  );

  const [bulkUpload] = useMutation(productsMutation.BULK_UPLOAD, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const [bulkUpdate] = useMutation(productsMutation.BULK_UPDATE, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  useEffect(() => {
    if (selectedProduct) {
      setUpdatedPrice(selectedProduct?.price);
      setUpdatedQuantity(selectedProduct?.qty);
    }
  }, [selectedProduct]);

  useEffect(() => {
    const transformed = EditPriceProductData?.map((order: any, index: any) => ({
      id: index,
      sku: order?.sku,
      itemName: order?.name,
      image: order?.image,
      itemPrice: order?.price,
      quantity: order?.qty,
      status: order?.status,
    }));
    setEditPriceTableData(transformed);
  }, [EditPriceProductData]);

  //eslint-disable-next-line
  useEffect(() => {
    const pageInfo = editProductData?.sellerProductsList[0]?.pageInfo;
    const totalPagesA = Math.round(parseInt(pageInfo?.total_pages) / 10);
    if (typeof totalPagesA == "number") setTotalPage(totalPagesA);
  });

  const headCellsForEditPrice = [
    { id: "sku", numeric: false, disablePadding: false, label: t("Home_Dashboard.products.sku") },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.products.item_name"),
    },
    { id: "image", numeric: false, disablePadding: false, label: t("Home_Dashboard.products.image") },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: `${t("Home_Dashboard.products.item_price")}(${currency})`,
    },
    { id: "quantity", numeric: true, disablePadding: false, label: t("Home_Dashboard.products.qty") },
    { id: "status", numeric: false, disablePadding: false, label: t("Home_Dashboard.products.status") },
  ];
 

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setDebouncedSearchTerm("");
  };

  const additionalActionsForeditPrice = [
    {
      label: t("Home_Dashboard.products.update"),
      buttonLabel: t("Home_Dashboard.products.update"),
      onClick: async (row: any) => {
        const index = updatedRows.findIndex(
          (updatedRow) => updatedRow.id === row.id
        );
        if (index !== -1) {
          const updatedRowAfterClick = {
            ...row,
            itemPrice: updatedRows[index].itemPrice,
            quantity: updatedRows[index].quantity,
          };
          const priceForUpdateApi = updatedRowAfterClick.itemPrice;
          const quantityForUpdateApi = updatedRowAfterClick.quantity;
          const skuForUpdateApi = updatedRowAfterClick.sku;
          try {
            await updateProduct({
              variables: {
                input: {
                  sku: skuForUpdateApi,
                  price: priceForUpdateApi,
                  qty: quantityForUpdateApi,
                  type: "update",
                },
              },
            });
            toast.success(t("Home_Dashboard.products.update_success_msg"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            editProductDataRefetch();
          } catch (error) {
            console.error("Error saving password 22:", error);
            toast.error("Error updating", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      },
    },
  ];

  const menuItems = [
    t("Home_Dashboard.products.create_product_title"),
    t("Home_Dashboard.products.add_product_title"),
    t("Home_Dashboard.products.bulk_upload_title"),
    t("Home_Dashboard.products.easy_edit_title"),
    t("Home_Dashboard.products.bulk_price_title"),
  ];

  const handleMenuItem = (itemName: any) => {
    setSelectedMenuItem(itemName);
  };

  const handleProductFileUpload = async (event: any) => {
    const files = event.target.files;
    const newFiles: UploadedFile[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
      try {
        const base64String = await readFileAsBase64(file);
        const finalBase64String = `data:image/${fileExtension};base64,${base64String}`;
        newFiles.push({ name: file.name, base64String: finalBase64String });
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
    // Update state with the new array of files
    setUploadedFiles((prevUploadedFiles) => [
      ...prevUploadedFiles,
      ...newFiles,
    ]);
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target?.result) {
          const base64String = btoa(
            new Uint8Array(event.target.result as ArrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          resolve(base64String);
        } else {
          reject(new Error("Error reading file"));
        }
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleSampleDownload = async () => {
    try {
      const response = await fetch("/csv_files/Bulk_Upload.csv");
      const csvData = await response.text();

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "Bulk_Upload.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading CSV file:", error);
    }
  };
  const { data: bullProductData } = useQuery(
    productPageQuery.EDIT_PRICE_QUANTITY_LIST,
    {
      variables: bulkProductVariables,
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
        onerror(error: any) {
          console.error("error 10: ", error);
        },
      },
    }
  );

  const {
    //eslint-disable-next-line
    data: bulkDonwload,
    refetch: refetchBulkDownload,
    //eslint-disable-next-line
    error: bulkDonwloadError,
  } = useQuery(productPageQuery.BULK_PRODUCT_DOWNLOAD, {
    variables: bulkProductVariables,
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
      onerror(error: any) {
        console.error("error 10: ", error);
      },
    },
  });
  const handleDownloadAllProducts = async () => {
    setBulkProductVariables({
      pageSize: 300,
      currentPage: 1,
      downloadAll: "true",
      type: "seller_products",
    });

    refetchBulkDownload()
      .then((res: any) => {
        try {
          window.open(res?.data?.sellerProductDownload[0]?.download_url);
        } catch (error) {
          console.error("Error downloading CSV file:", error);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  useEffect(() => {
    console.log("productsData: ", bullProductData);
  }, [bullProductData]);

  const handleProductSelect = (product: searchProduct) => {
    setSelectedProduct(product);
    setSearchResultModalShow(false);
  };

  //add a product price update
  const handlePriceChange = (event: any) => {
    setUpdatedPrice(event.target.value);
  };

  //add a product quantity update
  const handleQuantityChange = (event: any) => {
    setUpdatedQuantity(event.target.value);
  };

  const finalSku = selectedProduct?.sku;
  const finalPrice = updatedPrice;
  const finalQty = updatedQuantity;

  const handleAddProduct = async (e: any) => {
    e.preventDefault();
    try {
      //eslint-disable-next-line
      const response = await addProduct({
        variables: {
          input: {
            sku: finalSku,
            price: finalPrice,
            qty: finalQty,
            type: "add",
          },
        },
      });

      toast.success(t("Home_Dashboard.products.product_added_success"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error: any) {
      console.error("Error saving product:", error);

      // Check if the error is a network error
      if (error?.networkError) {
        // Display network error message
        const networkErrorMessage =
          error.networkError.result?.errors?.[0]?.message ||
          error.networkError.message ||
          "Network error: Failed to fetch";
        toast.error(`${networkErrorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // Check if there are GraphQL errors
      else if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        // Display the first GraphQL error message
        const errorMessage =
          error.graphQLErrors[0]?.message || "Unknown GraphQL error";
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // Fallback for any other errors
      else {
        toast.error("An unknown error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleEditPriceQuantityChange = (value: any) => {
    setUpdatedRows(value);
  };

  const handleDataForNewProduct = (e: any, fieldName: any) => {
    const { value } = e.target;
    setDataForNewProduct((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const submitCreateProductData = async (e: any) => {
    e.preventDefault();
    try {
      //eslint-disable-next-line
      const response = await createProduct({
        variables: {
          input: {
            name: dataforNewProduct.name,
            sku: dataforNewProduct.sku,
            price: dataforNewProduct.price,
            qty: dataforNewProduct.qty,
            weight: dataforNewProduct.weight,
            ean: dataforNewProduct.ean,
            shortDescription: dataforNewProduct.shortDescription,
            description: dataforNewProduct.description,
            images: uploadedFiles?.map((image) => ({
              name: image.name,
              base64_encoded_file: image.base64String,
            })),
          },
        },
      }).catch((error) => {
        console.error("createProductError 33 error: ", error);
      });
      createProductError?.graphQLErrors.map(({ message }, i) =>
        console.error("createProductError 33 error:  ", i, message)
      );
      //eslint-disable-next-line
      const link = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `createProductError [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      });

    } catch (error) {
      console.error(
        "Error saving password 11:",
        error,
        createProductError?.cause
      );
      if (
        editProductDataError &&
        errorEditProductData === "Seller is not loggedin"
      ) {
        toast.error(`${errorEditProductData}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(t("Home_Dashboard.products.add_err"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleCheckboxChange = (index: any) => {
    if (index === selectedFileIndex) {
      return;
    }
    setSelectedFileIndex(index);
  };

  const removeProductImage = (indexToRemove: any) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const errorUpdateProductData = updateProductDataError?.message;

  const handleBulkSheetUpload = () => {
    fileInputRef.current?.click(); 
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const allowedExtensions = ["csv"];
    const fileExtension = file?.name.split(".").pop()?.toLowerCase() || "";

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(t("Home_Dashboard.products.csv_err"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const base64String = btoa(
          new Uint8Array(reader.result as ArrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const finalBase64String = `data:application/${fileExtension};base64,${base64String}`;
        setBulkUploadStr(finalBase64String);
        toast.success(
          t("Home_Dashboard.products.bulk_upload_success"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        console.error("Error reading file");
        toast.error(t("Home_Dashboard.products.bulk_upload_err"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    console.log("BulkUploadState:", bulkUploadStr);
  }, [bulkUploadStr]);

  const uploadBulkSheet = async (e: any) => {
    e.preventDefault();
    try {
      //eslint-disable-next-line
      const response = await bulkUpload({
        variables: {
          input: {
            type: "add",
            file: bulkUploadStr,
          },
        },
      });
      toast.success(t("Home_Dashboard.products.bulk_upload_sheet_success"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error: any) {
      console.error("Error saving product:", error);

      // Check if the error is a network error
      if (error?.networkError) {
        // Display network error message
        const networkErrorMessage =
          error.networkError.result?.errors?.[0]?.message ||
          error.networkError.message ||
          "Network error: Failed to fetch";
        toast.error(`${networkErrorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // Check if there are GraphQL errors
      else if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        // Display the first GraphQL error message
        const errorMessage =
          error.graphQLErrors[0]?.message || "Unknown GraphQL error";
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // Fallback for any other errors
      else {
        toast.error("An unknown error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleBulkSheetUpdate = () => {
    InputRefForBulkUpdate.current?.click(); // This triggers the click event on the hidden file input
  };

  const handleFileForBulkUpdate = (event: any) => {
    const file = event.target.files[0];
    const allowedExtensions = ["csv"];
    const fileExtension = file?.name.split(".").pop()?.toLowerCase() || "";

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error(t("Home_Dashboard.products.csv_err"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const base64String = btoa(
          new Uint8Array(reader.result as ArrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const finalBase64String = `data:application/${fileExtension};base64,${base64String}`;
        setBulkUpdateStr(finalBase64String);
        toast.info("Uploaded Bulk update sheet ! Remember to update product", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        console.error("Error reading file");
        toast.error("Error uploading Bulk update sheet !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (InputRefForBulkUpdate.current) {
        InputRefForBulkUpdate.current.value = "";
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    console.log("BulkUploadState:", bulkUpdateStr);
  }, [bulkUpdateStr]);

  const uploadBulkUpdateSheet = async (e: any) => {
    e.preventDefault();
    try {
      const response = await bulkUpdate({
        variables: {
          input: {
            type: "update",
            file: bulkUpdateStr,
          },
        },
      });
      const data = response.data;
      console.log("data after saving:", data);
      toast.success("Bulk update sheet saved successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      editProductDataRefetch();
    } catch (error: any) {
      console.error("Error saving product:", error);

      // Check if the error is a network error
      if (error?.networkError) {
        // Display network error message
        const networkErrorMessage =
          error.networkError.result?.errors?.[0]?.message ||
          error.networkError.message ||
          "Network error: Failed to fetch";
        toast.error(`${networkErrorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // Check if there are GraphQL errors
      else if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        // Display the first GraphQL error message
        const errorMessage =
          error.graphQLErrors[0]?.message || "Unknown GraphQL error";
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // Fallback for any other errors
      else {
        toast.error("An unknown error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const handleSetSearchTerms = (e: any) => {
    setSearchProductTerm(e.target.value);
    if (e.target.value === "") {
      setDebouncedSearchTerm("");
    }
  };
  const handleSearchProduct = (e: any) => {
    editProductDataRefetch();
  };
  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
    setSelectedPage(value);
  };

  return (
    <div className="product-form-section">
      <MobileMenuBar
        menuItems={menuItems}
        onItemClick={handleMenuItem}
        selectedMenuItem={selectedMenuItem}
      />
      <Box sx={{ width: "100%" }} className="orders-tabs mobile__view__none">
        <Grid item xs={12} sm={12} md={7}>
          <Box
            className="order-tabs-head"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className={`order-tabs-head ${i18n.language === 'ar' ? 'rtl-tabs' : ''}`}
              variant="scrollable"
              scrollButtons="auto"
              // TabScrollButtonProps={{
              //   style: {
              //     transform: i18n.language === 'ar' ? 'rotate(180deg)' : 'none',
              //   },
              // }}
            >
              <Tab
                label={t("Home_Dashboard.products.create_product_title")}
                {...a11yProps(0)}
                className="tabs-title"
              />
              <Tab
                label={t("Home_Dashboard.products.add_product_title")}
                {...a11yProps(1)}
                className="tabs-title"
              />
              <Tab
                label={t("Home_Dashboard.products.bulk_upload_title")}
                {...a11yProps(2)}
                className="tabs-title"
              />
              <Tab
                label={t("Home_Dashboard.products.easy_edit_title")}
                {...a11yProps(3)}
                className="tabs-title"
              />
              <Tab
                label={t("Home_Dashboard.products.bulk_price_title")}
                {...a11yProps(4)}
                className="tabs-title"
              />
            </Tabs>
          </Box>
        </Grid>
        <CustomTabPanel value={value} index={0}>
          <Box className="add-product-tab ">
            {<Toaster />}
            <div className="main-body">
              <Grid className="company-upper" container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.Product_name")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="productName"
                      placeholder={t("Home_Dashboard.products.Product_name")}
                      value={dataforNewProduct.name}
                      onChange={(e: any) => handleDataForNewProduct(e, "name")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.sku")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="sku"
                      placeholder={t("Home_Dashboard.products.sku")}
                      value={dataforNewProduct.sku}
                      onChange={(e: any) => handleDataForNewProduct(e, "sku")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.selling_price")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="sellingPrice"
                      placeholder={t("Home_Dashboard.products.selling_price")}
                      value={dataforNewProduct.price}
                      onChange={(e: any) => handleDataForNewProduct(e, "price")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.qty")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="quantity"
                      placeholder={t("Home_Dashboard.products.qty")}
                      value={dataforNewProduct.qty}
                      onChange={(e: any) => handleDataForNewProduct(e, "qty")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik">{t("Home_Dashboard.products.weight")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="weight"
                      placeholder={t("Home_Dashboard.products.weight")}
                      value={dataforNewProduct.weight}
                      onChange={(e: any) =>
                        handleDataForNewProduct(e, "weight")
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.ean/upc")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="barcodeNumber"
                      placeholder={t("Home_Dashboard.products.ean/upc")}
                      value={dataforNewProduct.ean}
                      onChange={(e: any) => handleDataForNewProduct(e, "ean")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik">{t("Home_Dashboard.products.short_desc")}</label>
                    <TextField
                      placeholder={t("Home_Dashboard.products.short_desc")}
                      required
                      fullWidth
                      multiline
                      rows={4}
                      maxRows={10}
                      value={dataforNewProduct.shortDescription}
                      onChange={(e: any) =>
                        handleDataForNewProduct(e, "shortDescription")
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik"> {t("Home_Dashboard.products.desc")} </label>
                    <TextField
                      placeholder={t("Home_Dashboard.products.desc")}
                      required
                      fullWidth
                      multiline
                      rows={4}
                      maxRows={10}
                      value={dataforNewProduct.description}
                      onChange={(e: any) =>
                        handleDataForNewProduct(e, "description")
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                className="company-upper"
                container
                spacing={2}
                sx={{ padding: "30px" }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <h4 className="block-title">{t("Home_Dashboard.products.upload_img")}</h4>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="upload-img">
                    <input
                      type="file"
                      id="uploadfile"
                      multiple
                      onChange={handleProductFileUpload}
                    />
                    <label htmlFor="uploadfile" className="btn-1">
                      <CloudUploadIcon />
                      {t("Home_Dashboard.products.upload_file")}
                    </label>
                  </Box>
                </Grid>
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <Grid item xs={12} md={6} lg={6}>
                    <h4>{t("Home_Dashboard.products.upload_img")}</h4>
                    {/* <div className="status-upload-img">
                    <div className='status-upload-block'>
                      <div className='upload-img-item'>
                        <img src={require("../../../assets/images/upload-img.png")} alt="upload-img" />
                      </div>
                      <div className='progress-block'>
                        <div className='progress-block-label'>
                          <div className="img-name">Photo.png </div>
                          <div className="img-size">7.5 mb</div>
                          <div className="img-close">X</div>
                        </div>
                        <div className='linear-progress-bar'>
                          <BorderLinearProgress variant="determinate" value={80} />
                        </div>
                        <div className='process-block-label'>
                          <div className="process-done">37% done</div>
                          <div className="process-time">90KB/sec</div>
                        </div>
                      </div>
                    </div>
                    <div className="select-items">
                      <FormControlLabel required control={<Checkbox />} label="Select Main Photo" />
                    </div>
                    <hr />
                  </div> */}
                    {uploadedFiles?.map((file, index) => (
                      <div className="status-upload-img" key={index}>
                        <div className="status-upload-block">
                          <div className="upload-img-item">
                            <img
                              src={file.base64String}
                              alt={`Uploaded file ${index}`}
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                          <div className="progress-block">
                            <div className="progress-block-label">
                              <div className="img-name">{file.name}</div>
                              <div className="img-close">
                                <CancelIcon
                                  onClick={() => removeProductImage(index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="select-items">
                          <FormControlLabel
                            required
                            control={
                              <Checkbox
                                checked={selectedFileIndex === index}
                                onChange={() => handleCheckboxChange(index)}
                              />
                            }
                            label={t("Home_Dashboard.products.select_main_photo")}
                          />
                        </div>
                        <hr />
                      </div>
                    ))}
                  </Grid>
                )}
              </Grid>
            </div>
            <div className="btn-group">
              <Button className="save-btn" onClick={submitCreateProductData}>
                <Typography className="btn-text">{t("Home_Dashboard.products.save_product")}</Typography>
              </Button>
              <Button className="cancel-btn">
                <Typography className="btn-text">{t("Home_Dashboard.products.cancle")}</Typography>
              </Button>
            </div>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="add-product-jebelzId">
            {<Toaster />}
            <div className="main">
              <Grid
                className="company-upper"
                container
                spacing={2}
                sx={{ padding: "30px" }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap product-search-box">
                    <label className="unastrik">{t("Home_Dashboard.products.search_by_prod")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="jebelzId"
                      placeholder={t("Home_Dashboard.products.search_by_prod")}
                      value={searchTerm}
                      onChange={(e: any) => setSearchTerm(e.target.value)}
                      onFocus={() => setSearchResultModalShow(true)}
                      // onFocus={() => {
                      //   if (searchTerm.trim() === "") {
                      //     setSearchResultModalShow(false);
                      //   } else {
                      //     setSearchResultModalShow(true);
                      //   }
                      // }}
                    />
                    {searchResultModalShow &&
                      searchProductData &&
                      searchProductData?.sellerProducts && (
                        <div className="product-search-result">
                          <List
                            sx={{ width: "100%", bgcolor: "background.paper" }}
                          >
                            {searchProductData?.sellerProducts?.map(
                              (product: any, index: number) => (
                                <ListItem
                                  onClick={() => handleProductSelect(product)}
                                  key={index}
                                >
                                  <ListItemAvatar>
                                    <div className="product-img">
                                      <img
                                        src={product?.image}
                                        alt={product?.name}
                                      />
                                    </div>
                                  </ListItemAvatar>
                                  <ListItemText primary={product?.name} />
                                </ListItem>
                              )
                            )}
                          </List>
                        </div>
                      )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik visibility-hidden-label">
                      1
                    </label>
                    <Button className="load-btn">
                      <Typography className="btn-text">{t("Home_Dashboard.products.load_product")}</Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.product_name")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="productName"
                      placeholder={t("Home_Dashboard.products.product_name")}
                      value={selectedProduct?.name}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.sku")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="sku"
                      placeholder={t("Home_Dashboard.products.sku")}
                      value={selectedProduct?.sku}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.selling_price")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="sellingPrice"
                      placeholder={t("Home_Dashboard.products.selling_price")}
                      value={updatedPrice}
                      onChange={handlePriceChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.qty")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="quantity"
                      placeholder={t("Home_Dashboard.products.qty")}
                      value={updatedQuantity}
                      onChange={handleQuantityChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik">{t("Home_Dashboard.products.weight")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="weight"
                      placeholder={t("Home_Dashboard.products.weight")}
                      value={selectedProduct?.weight}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.ean/upc")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="barcodeNumber"
                      placeholder={t("Home_Dashboard.products.ean/upc")}
                      value={selectedProduct?.ean}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>
              {selectedProduct?.image_gallery && (
                <Box className="product-images">
                  <label className="unastrik">{t("Home_Dashboard.products.product_images")}</label>
                  <div>
                    {selectedProduct?.image_gallery &&
                      selectedProduct?.image_gallery?.map(
                        (image: any, index: any) => (
                          <div key={index}>
                            <Grid item xs={6} md={6} lg={6}>
                              <div className="img-wrapper">
                                <div className="img-name">
                                  <img
                                    src={image.url}
                                    alt={`Product ${index + 1}`}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Divider />
                          </div>
                        )
                      )}
                  </div>
                </Box>
              )}
              {/* <Box className='product-images'>
                <label className='unastrik' >Product Images</label>
                <div>
                  {selectedProduct?.image_gallery && selectedProduct?.image_gallery?.map((image: any, index: any) => (
                    <div key={index}>
                      <Grid item xs={6} md={6} lg={6}>
                        <div className='img-wrapper'>
                          <div className='img-name'>
                            <img src={image.url} alt={`Product ${index + 1}`} />
                          </div>
                        </div>
                      </Grid>
                      <Divider />
                    </div>
                  ))}
                </div>
              </Box> */}
            </div>
            <div className="btn-group">
              <Button className="save-btn" onClick={handleAddProduct}>
                <Typography className="btn-text">{t("Home_Dashboard.products.save_product")}</Typography>
              </Button>
              <Button className="cancel-btn">
                <Typography className="btn-text">{t("Home_Dashboard.products.cancle")}</Typography>
              </Button>
            </div>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box className="bulk-upload">
            {<Toaster />}
            <div className="main">
              <List>
                <ListItem className="wrapper">
                  <ListItemText className="list-text">
                    <Typography className="text">1. {t("Home_Dashboard.products.sample_download")}</Typography>
                  </ListItemText>
                  <ListItemIcon
                    className="list-icon"
                    onClick={handleSampleDownload}
                  >
                    <CloudDownloadIcon className="svg-icon" />
                  </ListItemIcon>
                </ListItem>
                <Divider className="divider" />
                <ListItem className="wrapper">
                  <ListItemText className="list-text">
                    <Typography className="text">
                      2. {t("Home_Dashboard.products.attach_bulksheet")}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon className="list-icon">
                    <Button
                      className="sheet-btn"
                      onClick={handleBulkSheetUpload}
                    >
                      <Typography variant="h5">{t("Home_Dashboard.products.attach_bulksheet")}</Typography>
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </ListItemIcon>
                </ListItem>
                <Divider className="divider" />
              </List>
            </div>
            <div className="btn-group">
              <Button className="save-btn" onClick={uploadBulkSheet}>
                <Typography className="btn-text">{t("Home_Dashboard.products.update_product")}</Typography>
              </Button>
              <Button className="cancel-btn">
                <Typography className="btn-text">{t("Home_Dashboard.products.cancle")}</Typography>
              </Button>
            </div>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {updateProductDataError ? (
            <p className="error-text">{errorUpdateProductData}</p>
          ) : updateProductDataLoading ? (
            <CircularProgress className="loader" />
          ) : (
            <Box className="edit-price">
              {<Toaster />}
              <div className="main">
                <div className="btn-group">
                  <TextField
                    placeholder={t("Home_Dashboard.products.search")}
                    name="search_product"
                    id="search_product"
                    onChange={handleSetSearchTerms}
                  />
                  <Button
                    className="cancel-btn"
                    style={{ backgroundColor: "#ddd" }}
                    onClick={handleSearchProduct}
                  >
                    <Typography className="btn-text">{t("Home_Dashboard.products.search")}</Typography>
                  </Button>
                </div>
                {editProductDataLoading ? (
                  <CircularProgress />
                ) : editPriceTableData && editPriceTableData?.length > 0 ? (
                  <DashBoardTable
                    headCells={headCellsForEditPrice}
                    rows={editPriceTableData}
                    ButtonActions={additionalActionsForeditPrice}
                    onValueChange={handleEditPriceQuantityChange}
                  />
                ) : (
                  <NoProductFound />
                )}
              </div>
              {/* <div className='btn-group'>
              <Button className='save-btn'><Typography className='btn-text'>Update Product</Typography></Button>
              <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
              <Button variant="contained" color="primary" onClick={() => action.onClick(row)} className={`custom-btn-${index}`}>
                          {action.buttonLabel}
                        </Button>
            </div> */}
              {editPriceTableData && editPriceTableData.length > 0 && (
                <Box sx={{ pt: 5 }}>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPage ? totalPage : 10}
                      shape="rounded"
                      onChange={handlePageChange}
                      defaultPage={selectedPage}
                    />
                  </Stack>
                </Box>
              )}
            </Box>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Box className="bulk-price">
            {<Toaster />}
            <div className="main">
              <List>
                <ListItem className="wrapper">
                  <ListItemText className="list-text">
                    <Typography className="text">
                      1. {t("Home_Dashboard.products.download_all_product")}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon
                    className="list-icon"
                    onClick={handleDownloadAllProducts}
                  >
                    <CloudDownloadIcon className="svg-icon" />
                  </ListItemIcon>
                </ListItem>
                <Divider className="divider" />
                <ListItem className="wrapper">
                  <ListItemText className="list-text">
                    <Typography className="text">
                      2. {t("Home_Dashboard.products.upload_all_products")}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon className="list-icon">
                    <Button
                      className="sheet-btn"
                      onClick={handleBulkSheetUpdate}
                    >
                      <Typography variant="h5">{t("Home_Dashboard.products.upload_products")}</Typography>
                    </Button>
                    <input
                      ref={InputRefForBulkUpdate}
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileForBulkUpdate}
                    />
                  </ListItemIcon>
                </ListItem>
                <Divider className="divider" />
              </List>
            </div>
            <div className="btn-group">
              <Button className="save-btn" onClick={uploadBulkUpdateSheet}>
                <Typography className="btn-text">{t("Home_Dashboard.products.submit")}</Typography>
              </Button>
              <Button className="cancel-btn">
                <Typography className="btn-text">{t("Home_Dashboard.products.cancle")}</Typography>
              </Button>
            </div>
          </Box>
        </CustomTabPanel>
      </Box>
      {/* MobileContent */}
      <Box className="mobile__view__block">
        {selectedMenuItem === "Create New Product" && (
          <Box className="add-product-tab ">
            {<Toaster />}
            <div className="main-body">
              <Grid className="company-upper" container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.product_name")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="productName"
                      placeholder={t("Home_Dashboard.products.product_name")}
                      value={dataforNewProduct.name}
                      onChange={(e: any) => handleDataForNewProduct(e, "name")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.sku")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="sku"
                      placeholder={t("Home_Dashboard.products.sku")}
                      value={dataforNewProduct.sku}
                      onChange={(e: any) => handleDataForNewProduct(e, "sku")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.selling_price")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="sellingPrice"
                      placeholder={t("Home_Dashboard.products.selling_price")}
                      value={dataforNewProduct.price}
                      onChange={(e: any) => handleDataForNewProduct(e, "price")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.qty")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="quantity"
                      placeholder={t("Home_Dashboard.products.qty")}
                      value={dataforNewProduct.qty}
                      onChange={(e: any) => handleDataForNewProduct(e, "qty")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik">{t("Home_Dashboard.products.weight")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="weight"
                      placeholder={t("Home_Dashboard.products.weight")}
                      value={dataforNewProduct.weight}
                      onChange={(e: any) =>
                        handleDataForNewProduct(e, "weight")
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.ean/upc")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="barcodeNumber"
                      placeholder={t("Home_Dashboard.products.ean/upc")}
                      value={dataforNewProduct.ean}
                      onChange={(e: any) => handleDataForNewProduct(e, "ean")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik">{t("Home_Dashboard.products.short_desc")} </label>
                    <TextField
                      placeholder={t("Home_Dashboard.products.short_desc")}
                      required
                      fullWidth
                      multiline
                      rows={4}
                      maxRows={10}
                      value={dataforNewProduct.shortDescription}
                      onChange={(e: any) =>
                        handleDataForNewProduct(e, "shortDescription")
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik"> {t("Home_Dashboard.products.desc")} </label>
                    <TextField
                      placeholder={t("Home_Dashboard.products.desc")}
                      required
                      fullWidth
                      multiline
                      rows={4}
                      maxRows={10}
                      value={dataforNewProduct.description}
                      onChange={(e: any) =>
                        handleDataForNewProduct(e, "description")
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                className="company-upper"
                container
                spacing={2}
                sx={{ padding: "30px" }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <h4 className="block-title">{t("Home_Dashboard.products.upload_img")}</h4>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="upload-img">
                    <input
                      type="file"
                      id="uploadfile"
                      multiple
                      onChange={handleProductFileUpload}
                    />
                    <label htmlFor="uploadfile" className="btn-1">
                      <CloudUploadIcon />
                      {t("Home_Dashboard.products.upload_file")}
                    </label>
                  </Box>
                </Grid>
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <Grid item xs={12} md={6} lg={6}>
                    <h4>{t("Home_Dashboard.products.upload_img")}</h4>
                    {uploadedFiles?.map((file, index) => (
                      <div className="status-upload-img" key={index}>
                        <div className="status-upload-block">
                          <div className="upload-img-item">
                            <img
                              src={file.base64String}
                              alt={`Uploaded file ${index}`}
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                          <div className="progress-block">
                            <div className="progress-block-label">
                              <div className="img-name">{file.name}</div>
                              <div className="img-close">
                                <CancelIcon
                                  onClick={() => removeProductImage(index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="select-items">
                          <FormControlLabel
                            required
                            control={
                              <Checkbox
                                checked={selectedFileIndex === index}
                                onChange={() => handleCheckboxChange(index)}
                              />
                            }
                            label={t("Home_Dashboard.products.select_main_photo")}
                          />
                        </div>
                        <hr />
                      </div>
                    ))}
                  </Grid>
                )}
              </Grid>
            </div>
            <div className="btn-group">
              <Button className="save-btn" onClick={submitCreateProductData}>
                <Typography className="btn-text">{t("Home_Dashboard.products.save_product")}</Typography>
              </Button>
              <Button className="cancel-btn">
                <Typography className="btn-text">{t("Home_Dashboard.products.cancle")}</Typography>
              </Button>
            </div>
          </Box>
        )}
        {selectedMenuItem === "Add a Product" && (
          <Box className="add-product-jebelzId">
            {<Toaster />}
            <div className="main">
              <Grid
                className="company-upper"
                container
                spacing={2}
                sx={{ padding: "30px" }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap product-search-box">
                    <label className="unastrik">{t("Home_Dashboard.products.search_by_prod")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="jebelzId"
                      placeholder={t("Home_Dashboard.products.search_by_prod")}
                      value={searchTerm}
                      onChange={(e: any) => setSearchTerm(e.target.value)}
                      // onFocus={() => setSearchResultModalShow(true)}
                      onFocus={() => {
                        if (searchTerm.trim() === "") {
                          setSearchResultModalShow(false);
                        } else {
                          setSearchResultModalShow(true);
                        }
                      }}
                    />
                    {searchResultModalShow &&
                      searchProductData &&
                      searchProductData?.sellerProducts && (
                        <div className="product-search-result">
                          <List
                            sx={{ width: "100%", bgcolor: "background.paper" }}
                          >
                            {searchProductData?.sellerProducts?.map(
                              (product: any, index: number) => (
                                <ListItem
                                  onClick={() => handleProductSelect(product)}
                                  key={index}
                                >
                                  <ListItemAvatar>
                                    <div className="product-img">
                                      <img
                                        src={product?.image}
                                        alt={product?.name}
                                      />
                                    </div>
                                  </ListItemAvatar>
                                  <ListItemText primary={product?.name} />
                                </ListItem>
                              )
                            )}
                          </List>
                        </div>
                      )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik visibility-hidden-label">
                      1
                    </label>
                    <Button className="load-btn">
                      <Typography className="btn-text">{t("Home_Dashboard.products.load_product")}</Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.Product_name")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="productName"
                      placeholder={t("Home_Dashboard.products.Product_name")}
                      value={selectedProduct?.name}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.sku")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="sku"
                      placeholder={t("Home_Dashboard.products.sku")}
                      value={selectedProduct?.sku}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.selling_price")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="sellingPrice"
                      placeholder={t("Home_Dashboard.products.selling_price")}
                      value={updatedPrice}
                      onChange={handlePriceChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.qty")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="quantity"
                      placeholder={t("Home_Dashboard.products.qty")}
                      value={updatedQuantity}
                      onChange={handleQuantityChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label className="unastrik">{t("Home_Dashboard.products.weight")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="weight"
                      placeholder={t("Home_Dashboard.products.weight")}
                      value={selectedProduct?.weight}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className="text-field-wrap">
                    <label>{t("Home_Dashboard.products.ean/upc")}</label>
                    <TextField
                      variant="outlined"
                      className="input-field"
                      name="barcodeNumber"
                      placeholder={t("Home_Dashboard.products.ean/upc")}
                      value={selectedProduct?.ean}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box className="product-images">
                {selectedProduct?.image_gallery && (
                  <>
                    <label className="unastrik">{t("Home_Dashboard.products.product_images")}</label>
                    <div>
                      {selectedProduct?.image_gallery &&
                        selectedProduct?.image_gallery?.map(
                          (image: any, index: any) => (
                            <div key={index}>
                              <Grid item xs={6} md={6} lg={6}>
                                <div className="img-wrapper">
                                  <div className="img-name">
                                    <img
                                      src={image.url}
                                      alt={`Product ${index + 1}`}
                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Divider />
                            </div>
                          )
                        )}
                    </div>
                  </>
                )}
              </Box>
            </div>
            <div className="btn-group">
              <Button className="save-btn" onClick={handleAddProduct}>
                <Typography className="btn-text">{t("Home_Dashboard.products.save_product")}</Typography>
              </Button>
              <Button className="cancel-btn">
                <Typography className="btn-text">{t("Home_Dashboard.products.cancle")}</Typography>
              </Button>
            </div>
          </Box>
        )}
        {selectedMenuItem === "Bulk Upload" && (
          <Box className="bulk-upload">
            {<Toaster />}
            <div className="main">
              <List>
                <ListItem className="wrapper">
                  <ListItemText className="list-text">
                    <Typography className="text">1. {t("Home_Dashboard.products.sample_download")}</Typography>
                  </ListItemText>
                  <ListItemIcon
                    className="list-icon"
                    onClick={handleSampleDownload}
                  >
                    <CloudDownloadIcon className="svg-icon" />
                  </ListItemIcon>
                </ListItem>
                <Divider className="divider" />
                <ListItem className="wrapper">
                  <ListItemText className="list-text">
                    <Typography className="text">
                      2. {t("Home_Dashboard.products.attach_bulksheet")}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon className="list-icon">
                    <Button
                      className="sheet-btn"
                      onClick={handleBulkSheetUpload}
                    >
                      <Typography variant="h5">{t("Home_Dashboard.products.upload_bulk_sheet")}</Typography>
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </ListItemIcon>
                </ListItem>
                <Divider className="divider" />
              </List>
            </div>
            <div className="btn-group">
              <Button className="save-btn" onClick={uploadBulkSheet}>
                <Typography className="btn-text">{t("Home_Dashboard.products.update_product")}</Typography>
              </Button>
              <Button className="cancel-btn">
                <Typography className="btn-text">{t("Home_Dashboard.products.cancle")}</Typography>
              </Button>
            </div>
          </Box>
        )}
        {selectedMenuItem === "Easy Edit Price & Quantity" && (
          <Box className="edit-price">
            <div className="main">
              <div className="btn-group">
                <TextField
                  placeholder={t("Home_Dashboard.products.search")}
                  name="search_product"
                  id="search_product"
                  onChange={handleSetSearchTerms}
                />
                <Button
                  className="cancel-btn"
                  style={{ backgroundColor: "#ddd" }}
                  onClick={handleSearchProduct}
                >
                  <Typography className="btn-text">{t("Home_Dashboard.products.search")}</Typography>
                </Button>
              </div>
              {updateProductDataError ? (
                <p className="error-text">{errorUpdateProductData}</p>
              ) : editProductDataLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {<Toaster />}
                  {editPriceTableData && editPriceTableData?.length > 0 ? (
                    <MobileTable
                      headCells={headCellsForEditPrice}
                      rows={editPriceTableData}
                      ButtonActions={additionalActionsForeditPrice}
                      onValueChange={handleEditPriceQuantityChange}
                    />
                  ) : (
                    <NoProductFound />
                  )}
                </>
              )}
            </div>
          </Box>
        )}
        {selectedMenuItem === "Bulk Price & Quantity Update" && (
          <Box className="bulk-price">
            {<Toaster />}
            <div className="main">
              <List>
                <ListItem className="wrapper">
                  <ListItemText className="list-text">
                    <Typography className="text">
                      1. {t("Home_Dashboard.products.download_all_product")}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon
                    className="list-icon"
                    onClick={handleDownloadAllProducts}
                  >
                    <CloudDownloadIcon className="svg-icon" />
                  </ListItemIcon>
                </ListItem>
                <Divider className="divider" />
                <ListItem className="wrapper">
                  <ListItemText className="list-text">
                    <Typography className="text">
                      2. {t("Home_Dashboard.products.upload_all_products")}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon className="list-icon">
                    <Button
                      className="sheet-btn"
                      onClick={handleBulkSheetUpdate}
                    >
                      <Typography variant="h5">{t("Home_Dashboard.products.upload_products")}</Typography>
                    </Button>
                    <input
                      ref={InputRefForBulkUpdate}
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileForBulkUpdate}
                    />
                  </ListItemIcon>
                </ListItem>
                <Divider className="divider" />
              </List>
            </div>
            <div className="btn-group">
              <Button className="save-btn" onClick={uploadBulkUpdateSheet}>
                <Typography className="btn-text">{t("Home_Dashboard.products.submit")}</Typography>
              </Button>
              <Button className="cancel-btn">
                <Typography className="btn-text">{t("Home_Dashboard.products.cancle")}</Typography>
              </Button>
            </div>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Product;
