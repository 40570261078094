import React, { useContext, useState } from "react";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "./Orders.scss";
import MobileMenuBar from "../../../Components/Mobile_Menubar/MobileMenubar";
import "../Product/Products.scss";
import PendingOrder from "./pending/PendingOrder";
import ConfirmedOrder from "./confirmed/ConfirmedOrder";
import AwbOrders from "./awb/AwbOrders";
import ShipedOrder from "./shipped/ShipedOrder";
import DeliveredOrder from "./delivered/DeliveredOrder";
import CancleOrder from "./canclled/CancleOrder";
import ArchivedOrder from "./archived/ArchivedOrder";
import { useMutation } from "@apollo/client";
import { CountryContext } from "../../..";
import * as mutations from "../../../Operations/Mutations";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Orders = () => {
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t }: { t: (key: string) => string } = useTranslation();
  const { store } = useContext(CountryContext);
  const navigate = useNavigate();
  //eslint-disable-next-line
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Pending");
  //eslint-disable-next-line
  const [filterOrder, setFilterOrder] = useState("Filter");
    //eslint-disable-next-line
  const [column, setColumn] = useState("Columns");
  const [value, setValue] = useState(0);
  const [tabchange, setTabChange] = useState(false);
  const [errMessage, setErrorMessage] = useState();
  const currencyMap : any  = {
    sell_in: { en: "INR", ar: "روبية هندية" },
    sell_sa: { en: "SAR", ar: "ريال سعودي" },
    sell_om: { en: "OMR", ar: "ريال عماني" },
    seller: { en: "AED", ar: "درهم" },
  };
  
  const defaultCurrency = { en: "AED", ar: "درهم" };
  
  const { en: currEn, ar: currAr } = currencyMap[store] || defaultCurrency;
  
  const currency = i18n.language === "ar" ? currAr : currEn;  

  const [revokeCustomertoken] = useMutation(
    mutations.logoutMutation.REVOKE_CUSTOMER_TOKEN,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleLogOut = async () => {
    try {
      await revokeCustomertoken();
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("preferredLanguage");
      navigate("/");
    } catch (error) {
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("preferredLanguage");
      navigate("/");
    }
  };

  if (errMessage === "Seller is not loggedin") {
    handleLogOut();
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedMenuItem(event.currentTarget.innerHTML.split("<span")[0]);
    setValue(newValue);
    setTabChange(true);
  };

  // const filter = ["Filter", "quarterly", "monthly", "weekly", "daily"];
  // const handlefilterChange = (event: SelectChangeEvent) => {
  //   setFilterOrder(event.target.value as string);
  // };

  // const columnPeriod = ["Columns", "quarterly", "monthly", "weekly", "daily"];
  // const handlecolumnChange = (event: SelectChangeEvent) => {
  //   setColumn(event.target.value as string);
  // };

  const menuItems = [
    t("Home_Dashboard.orders.pending"),
    t("Home_Dashboard.orders.confirmed"),
    t("Home_Dashboard.orders.awb"),
    t("Home_Dashboard.orders.shipped"),
    t("Home_Dashboard.orders.delivered"),
    t("Home_Dashboard.orders.cancelled"),
    t("Home_Dashboard.orders.archived"),
  ];

  const handleMenuItem = (itemName: string) => {
    const index = menuItems.indexOf(itemName);
    if (index !== -1) {
      setValue(index);
      setSelectedMenuItem(itemName);
      setMenuOpen(false);
    }
  };

  return (
    <div>
      {/* mobile tabs menu  */}
      <MobileMenuBar
        menuItems={menuItems}
        onItemClick={handleMenuItem}
        selectedMenuItem={selectedMenuItem}
      />
      <Box sx={{ width: "100%" }} className="orders-tabs ">
        <Grid className="filter-tabs-block" container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className={`order-tabs-head ${i18n.language === 'ar' ? 'rtl-tabs' : ''}`}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={t("Home_Dashboard.orders.pending")} {...a11yProps(0)} className="tabs-title" />
                <Tab
                  label={t("Home_Dashboard.orders.confirmed")}
                  {...a11yProps(1)}
                  className="tabs-title"
                />
                <Tab label={t("Home_Dashboard.orders.awb")} {...a11yProps(2)} className="tabs-title" />
                <Tab label={t("Home_Dashboard.orders.shipped")} {...a11yProps(3)} className="tabs-title" />
                <Tab
                  label={t("Home_Dashboard.orders.delivered")}
                  {...a11yProps(4)}
                  className="tabs-title"
                />
                <Tab
                  label={t("Home_Dashboard.orders.cancelled")}
                  {...a11yProps(5)}
                  className="tabs-title"
                />
                <Tab
                  label={t("Home_Dashboard.orders.archived")}
                  {...a11yProps(6)}
                  className="tabs-title"
                />
              </Tabs>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={3}>
            <div className="order-filter-section">
              <FormControl className="select-form">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterOrder}
                  label="Yearly"
                  onChange={handlefilterChange}
                >
                  {filter.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item}
                      className="menuitem-select"
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="select-form">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={column}
                  label="Yearly"
                  onChange={handlecolumnChange}
                >
                  {columnPeriod.map((item: any, index: any) => (
                    <MenuItem
                      key={index}
                      value={item}
                      className="menuitem-select"
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid> */}
        </Grid>
        <Box>
          <>
            <CustomTabPanel value={value} index={0}>
              <PendingOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
                setErrorMessage={setErrorMessage}
                currency={currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ConfirmedOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
                setErrorMessage={setErrorMessage}
                currency={currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <AwbOrders
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
                setErrorMessage={setErrorMessage}
                currency={currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <ShipedOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
                setErrorMessage={setErrorMessage}
                currency={currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <DeliveredOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
                setErrorMessage={setErrorMessage}
                currency={currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <CancleOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
                setErrorMessage={setErrorMessage}
                currency={currency}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <ArchivedOrder
                menuItems={menuItems}
                selectedMenuItem={selectedMenuItem}
                tabchange={tabchange}
                setErrorMessage={setErrorMessage}
                currency={currency}
              />
            </CustomTabPanel>
          </>
        </Box>
      </Box>
    </div>
  );
};

export default Orders;
