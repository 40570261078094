import { gql } from "@apollo/client";

export const ORDER_RETURN_STATUS = gql`
  mutation OrderReturnStatus($orderid: String!, $status: String!, $itemid: String!) {
    OrderReturnStatus(orderid: $orderid, status: $status, itemid: $itemid) {
      orderid
      status
      itemid
    }
  }
`;
