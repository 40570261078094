import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../../../..";
import { useMutation, useQuery } from "@apollo/client";
import { returnsPageQuery } from "../../../../Operations/Queries";
import DashBoardTable from "../../../../Components/Dashboard_Table/DashboardTable";
import NoProductFound from "../../../../Components/Noproduct/NoProductFound";
import {
  Box,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import MobileTable from "../../../../Components/Mobile_Table/MobileTable";
import { toast } from "react-toastify";
import { returnReqMutation } from "../../../../Operations/Mutations";
import Toaster from "../../../../Components/ToastMessage";

interface AfterDeliveryProps {
  array: { value: number; label: string }[];
  currency: string;
  menuItems: string[];
  selectedMenuItem: string;
  setErrorMessage: any;
}

const AfterDelivery: React.FC<AfterDeliveryProps> = ({
  array,
  currency,
  menuItems,
  selectedMenuItem,
  setErrorMessage,
}) => {
  const [afterDeliveryOrders, setAfterDeliveryOrders] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t }: { t: (key: string) => string } = useTranslation();
  const { store } = useContext(CountryContext);

  const {
    data: returnListData,
    refetch: refetchListData,
    error: afterDeliverError,
    loading: loadingAfterDeliveryData,
  } = useQuery(returnsPageQuery.RETURNS_AFTER_DELIVERY_REQUESTS, {
    variables: { type: "list" },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const returnRequestList = returnListData?.getsellerReturnRequests;
  const filteredReturnRequests = returnRequestList?.filter(
    (item: any) => item.return_status === "return_aws_generated"
  );

  if (afterDeliverError) {
    setErrorMessage(afterDeliverError?.message);
  }

  const [returnOrders] = useMutation(returnReqMutation.ORDER_RETURN_STATUS, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const additionalActionsForAfter = [
    {
      label: "Action",
      buttonLabel: "Action",
      onClick: async (row: any) => {
        console.log("row data is:", row);
        const processingToastId = toast.info(
          t("Home_Dashboard.orders.processing_req_toast"),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false, // Keep it open until request completes
          }
        );
        const finalPayload = {
          orderid: row.order,
          status: row.status,
          itemid: row.itemId,
        };
        console.log("finalPayload", finalPayload);
        try {
          //eslint-disable-next-line
          const response = await returnOrders({
            variables: finalPayload,
          });
          console.log("response", response);
          toast.dismiss(processingToastId);
          toast.success("Return Completed", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // alert(`Order Confirmed for ${orderNumber}`)
          refetchListData();
        } catch (error) {
          toast.dismiss(processingToastId);
          toast.error(t("Home_Dashboard.orders.confirmed_error_toast"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      },
    },
  ];

  const headCellsForAfterDelivery = [
    {
      id: "image",
      numeric: false,
      disablePadding: false,
      label: "Image",
    },
    {
      id: "itemId",
      numeric: false,
      disablePadding: false,
      label: "Item id",
    },
    {
      id: "order",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.return.order"),
    },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.return.itemName"),
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: `${t("Home_Dashboard.return.itemPrice")}(${currency})`,
    },
    {
      id: "quantity",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.return.quantity"),
    },
    {
      id: "itemTotal",
      numeric: true,
      disablePadding: false,
      label: `${t("Home_Dashboard.return.itemTotal")}(${currency})`,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "date",
    },
    // {
    //   id: "receivedDate",
    //   numeric: false,
    //   disablePadding: false,
    //   label: t("Home_Dashboard.return.receivedDate"),
    // },
  ];

  useEffect(() => {
    const transformed = filteredReturnRequests?.map(
      (order: any, index: any) => ({
        id: index,
        image: order.product_image,
        itemId: order.item_id,
        order: order.order_number,
        itemName: order.product_name,
        itemPrice: Number(order.refundable_amount).toFixed(2),
        date: order.updated_at.split(" ")[0],
        status: order.return_status,
        quantity: Number(order.item_qty).toFixed(0),
        itemTotal: Number(order.item_total_price).toFixed(0),
        // returnDate: order.return_date,
        // receivedDate: order.received_date,
      })
    );
    setAfterDeliveryOrders(transformed);
    //eslint-disable-next-line
  }, [returnRequestList]);

  const totalItems = afterDeliveryOrders?.length || 0;
  const totalPages = Math.ceil(totalItems / pageSize);

  const paginatedItems = afterDeliveryOrders
    ?.sort(
      (a: any, b: any) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    )
    ?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="returns-page">
        <Toaster />
        {afterDeliverError ? (
          <p className="error-text">{afterDeliverError?.message}</p>
        ) : loadingAfterDeliveryData ? (
          <CircularProgress />
        ) : afterDeliveryOrders && afterDeliveryOrders?.length > 0 ? (
          <DashBoardTable
            headCells={headCellsForAfterDelivery}
            rows={paginatedItems}
            ButtonActions={additionalActionsForAfter}
          />
        ) : (
          <NoProductFound />
        )}
      </div>
      {afterDeliveryOrders && afterDeliveryOrders.length > 10 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 5,
          }}
          className="deskPagination"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">Items per page:</Typography>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              variant="outlined"
              size="small"
            >
              {array.map((size: any) => (
                <MenuItem key={size.value} value={size.value}>
                  {size.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Pagination
            count={totalPages}
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            className="pagination-controls"
          />
        </Box>
      )}
      <Box className="mobile__view__block">
        {menuItems.map(
          (menuItem, index) =>
            selectedMenuItem === menuItem && (
              <>
                {afterDeliverError ? (
                  <p className="error-text">{afterDeliverError?.message}</p>
                ) : (
                  <MobileTable
                    key={index}
                    headCells={headCellsForAfterDelivery}
                    rows={paginatedItems}
                    ButtonActions={additionalActionsForAfter}
                  />
                )}
              </>
            )
        )}
      </Box>
    </>
  );
};

export default AfterDelivery;
