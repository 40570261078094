import React, { useContext, useState } from "react";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "./Returns.scss";
import MobileMenuBar from "../../../Components/Mobile_Menubar/MobileMenubar";
import { CountryContext } from "../../..";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AfterDelivery from "./afterDelivery/AfterDelivery";
import BeforeDelivery from "./beforeDelivery/BeforeDeliver";
import ReturnCompleted from "./returnCompleted/ReturnCompleted";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../Operations/Mutations";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Returns = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("Before Delivery");
  const [errMessage, setErrorMessage] = useState();
  const [value, setValue] = useState(0);
  const { t }: { t: (key: string) => string } = useTranslation();
  const { store } = useContext(CountryContext);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate()
  const array: any = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
  ];
  const currencyMap: any = {
    sell_in: { en: "INR", ar: "روبية هندية" },
    sell_sa: { en: "SAR", ar: "ريال سعودي" },
    sell_om: { en: "OMR", ar: "ريال عماني" },
    seller: { en: "AED", ar: "درهم" },
  };

  const defaultCurrency = { en: "AED", ar: "درهم" };
  const { en: currEn, ar: currAr } = currencyMap[store] || defaultCurrency;
  const currency = i18n.language === "ar" ? currAr : currEn;

  const menuItems = [
    t("Home_Dashboard.return.before_delivery"),
    t("Home_Dashboard.return.after_delivery"),
    "Return completed",
  ];

  const [revokeCustomertoken] = useMutation(
    mutations.logoutMutation.REVOKE_CUSTOMER_TOKEN,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleLogOut = async () => {
    try {
      await revokeCustomertoken();
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("preferredLanguage");
      navigate("/");
    } catch (error) {
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("preferredLanguage");
      navigate("/");
    }
  };

  if (errMessage === "Customer is not loggedin") {
    handleLogOut();
  }


  const handleMenuItem = (itemName: string) => {
    const index = menuItems.indexOf(itemName);
    if (index !== -1) {
      setValue(index);
      setSelectedMenuItem(itemName);
      // setMenuOpen(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="returns-page">
      <MobileMenuBar
        menuItems={menuItems}
        onItemClick={handleMenuItem}
        selectedMenuItem={selectedMenuItem}
      />
      <Box sx={{ width: "100%" }} className="orders-tabs mobile__view__none">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={7}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className={`order-tabs-head ${
                  i18n.language === "ar" ? "rtl-tabs" : ""
                }`}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={t("Home_Dashboard.return.before_delivery")}
                  {...a11yProps(0)}
                  className="tabs-title"
                />
                <Tab
                  label={t("Home_Dashboard.return.after_delivery")}
                  {...a11yProps(1)}
                  className="tabs-title"
                />
                <Tab
                  label="Return completed"
                  {...a11yProps(2)}
                  className="tabs-title"
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <>
        <CustomTabPanel value={value} index={0}>
          <BeforeDelivery
            array={array}
            currency={currency}
            menuItems={menuItems}
            selectedMenuItem={selectedMenuItem}
            setErrorMessage={setErrorMessage}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <AfterDelivery
            array={array}
            currency={currency}
            menuItems={menuItems}
            selectedMenuItem={selectedMenuItem}
            setErrorMessage={setErrorMessage}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ReturnCompleted
            array={array}
            currency={currency}
            menuItems={menuItems}
            selectedMenuItem={selectedMenuItem}
            setErrorMessage={setErrorMessage}
          />
        </CustomTabPanel>
      </>
    </div>
  );
};

export default Returns;
