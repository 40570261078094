import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import OrdersTable from "../../../../Components/Dashboard_Table/OrdersTable";
import { toast } from "react-toastify";
import { OrdersPageQuery } from "../../../../Operations/Queries";
import { CountryContext } from "../../../..";
import { ordersActionMutation } from "../../../../Operations/Mutations";
import Toaster from "../../../../Components/ToastMessage";
import MobileTable from "../mobileTable/MobileTable";
import NoProductFound from "../../../../Components/Noproduct/NoProductFound";
import { useTranslation } from "react-i18next";

// Define Props type
interface AwbOrderProps {
  menuItems: string[];
  selectedMenuItem: string;
  tabchange: boolean;
  setErrorMessage: any;
  currency:any;
}

// Define Order type
interface AWBOrder {
  id: string;
  order: string;
  date: string;
  itemName: string;
  itemPrice: number;
  quantity: number;
  itemTotal: number;
}

const AwbOrders: React.FC<AwbOrderProps> = ({
  menuItems,
  selectedMenuItem,
  tabchange,
  setErrorMessage,
  currency
}) => {
  const [awbOrders, setAwbOrders] = useState<AWBOrder[]>([]);
  const [ordersAwbPageInfo, setOrdersAwbPageInfo] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState({
    pending: 1,
    confirmed: 1,
    awb: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    archived: 1,
  });
  const [search, setSearch] = useState({
    pending: "",
    confirmed: "",
    awb: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    archived: "",
  });
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t }: { t: (key: string) => string } = useTranslation();

  const array: any = [
    { lable: "10", value: 10 },
    { lable: "20", value: 20 },
    { lable: "30", value: 30 },
  ];
  const { store } = useContext(CountryContext);

  const [pickupRequest] = useMutation(
    ordersActionMutation.ORDER_PICKUP_REQUEST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const {
    data: OrdersListAwbData,
    refetch: refetchAwbOrders,
    loading: awbLoading,
    error: awbError,
  } = useQuery(OrdersPageQuery.ORDERS_AWB, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
    variables: {
      orderNumber: search.awb,
      pageSize: pageSize,
      currentPage: currentPage.awb,
    },
  });

  if (awbError) {
    setErrorMessage(awbError);
  }

  // const totalItems =
  // OrdersListAwbData?.sellerOrdersList
  // ?.[0]?.items?.length || 0;
  // const totalPages = Math.ceil(totalItems / pageSize);

  // if(awbError){
  //   toast.error("seller is not logged in")
  //   localStorage.removeItem('selectedMenuItem');
  //   localStorage.removeItem('loggedInCust');
  //   navigate("/")
  // }

  const additionalActionsForAwb = [
    {
      label: t("Home_Dashboard.orders.pckp_lable"),
      buttonLabel: t("Home_Dashboard.orders.pckp_lable"),
      onClick: async (row: any) => {
        toast.info(t("Home_Dashboard.orders.processing_req_toast"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        // const rowId = row.id;
        const itemId = row.id;
        const orderNumber = row.order;
        const finalPayload = {
          action: "orderAction",
          status: "ship_item",
          order_number: orderNumber,
          item_id: itemId,
        };
        try {
          //eslint-disable-next-line
          const response = await pickupRequest({
            variables: finalPayload,
          });
          //   console.log("response from APi pickp requrest: ", response);

          toast.success(t("Home_Dashboard.orders.pickup_success"), {
            position: toast.POSITION.TOP_RIGHT,
          });

          refetchAwbOrders();
        } catch (error) {
          toast.error(t("Home_Dashboard.orders.awb_err"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          // console.log("error:", error)
        }
      },
    },
  ];

  useEffect(() => {
    // setOrdersAwbData(OrdersListAwbData?.sellerOrdersList?.[0]?.items);
    setOrdersAwbPageInfo(OrdersListAwbData?.sellerOrdersList?.[0]?.pageInfo);
    const transformed = OrdersListAwbData?.sellerOrdersList?.[0]?.items?.map(
      (order: any, index: any) => ({
        id: order?.item_id,
        order: order?.order_number,
        waybillNumber: order?.waybill_no,
        waybillFile: order?.waybill_file,
        date: order?.date,
        itemName: order?.product_name,
        itemPrice: order?.price,
        quantity: order?.qty,
        itemTotal: order?.sub_total,
        status: order?.status,
      })
    );
    setAwbOrders(transformed);
  }, [OrdersListAwbData]);

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage({ ...currentPage, awb: value });
    refetchAwbOrders({
      orderNumber: search.awb,
      pageSize: 20,
      currentPage: value,
    });
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage({ ...currentPage, awb: 1 });
    refetchAwbOrders({
      orderNumber: search.awb,
      pageSize: newSize,
      currentPage: 1,
    });
  };

  const handleSearch = () => {
    setCurrentPage({ ...currentPage, awb: 1 });
    refetchAwbOrders({
      orderNumber: search.awb,
      pageSize: 20,
      currentPage: 1,
    });
  };

  useEffect(() => {
    refetchAwbOrders();
    //eslint-disable-next-line
  }, [tabchange]);

  const headCellsForAwb = [
    { id: "order", numeric: false, disablePadding: false, label: t("Home_Dashboard.orders.order") },
    {
      id: "waybillNumber",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.orders.waybillNumber"),
    },
    { id: "date", numeric: false, disablePadding: false, label:  t("Home_Dashboard.orders.date"), },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label:  t("Home_Dashboard.orders.itemName"),
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label:  `${t("Home_Dashboard.orders.itemPrice")}(${currency})`,
    },
    { id: "quantity", numeric: true, disablePadding: false, label: t("Home_Dashboard.orders.quantity")  },
    {
      id: "itemTotal",
      numeric: true,
      disablePadding: false,
      label: `${t("Home_Dashboard.orders.itemTotal")}(${currency})`,
    },
    // {
    //   id: "shipmentStatus",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Shipment Status",
    // },
  ];

  return (
    <>
      <Toaster />
      <div className="main">
        <div
          className="btn-group"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <TextField
            placeholder={t("Home_Dashboard.orders.search_by")}
            name="search_pending_orders"
            id="search_product"
            value={search.awb}
            onChange={(e) => setSearch({ ...search, awb: e.target.value })}
            variant="outlined"
            className="search-text-field"
          />
          <Button
            onClick={handleSearch}
            className="search-button"
          >
            <Typography className="btn-text">{t("Home_Dashboard.orders.search")}</Typography>
          </Button>
        </div>
        {awbError ? (
          <p className="error-text">{awbError?.message}</p>
        ) : awbLoading ? (
          <CircularProgress />
        ) : awbOrders && awbOrders?.length > 0 ? (
          <OrdersTable
            headCells={headCellsForAwb}
            rows={awbOrders}
            ButtonActions={additionalActionsForAwb}
            pageChange={handlePageChange}
            pageType="awb"
            pageNumber={ordersAwbPageInfo?.current_page}
          />
        ) : (
          <NoProductFound />
        )}

        {awbOrders && awbOrders?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 5,
            }}
            className="deskPagination"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2">{t("Home_Dashboard.orders.item_per_page")}:</Typography>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outlined"
                size="small"
              >
                {array?.map((size: any) => (
                  <MenuItem key={size} value={size.value}>
                    {size.lable}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Pagination
              count={Math.ceil(80 / pageSize)}
              shape="rounded"
              page={currentPage.awb}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </div>
      {menuItems.map(
        (menuItem, index) =>
          selectedMenuItem === menuItem && (
            <div className="mobile-wrapper" key={index}>
              <MobileTable
                headCells={headCellsForAwb}
                rows={awbOrders}
                ButtonActions={additionalActionsForAwb}
                onChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                pageSize={pageSize}
                pageIs={currentPage.awb}
              />
            </div>
          )
      )}
    </>
  );
};

export default AwbOrders;
