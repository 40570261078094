import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Button,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
  TextField,
  Pagination,
  Stack,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import "./MobileTable.scss";

interface Data {
  [key: string]: any;
}

interface Props {
  headCells: any;
  rows: any;
  ButtonActions?: any;
  onValueChange?: any;
  onChange?: (event: any, value: number) => void;
  onRowsPerPageChange: (event: any, value: number) => void;
  pageSize: any;
  pageIs: any;
}

const MobileTable: React.FC<Props> = ({
  headCells,
  rows,
  ButtonActions,
  onValueChange,
  onChange,
  onRowsPerPageChange,
  pageSize,
  pageIs,
}) => {
  const [updatedRows, setUpdatedRows] = useState<Data[]>([]);

  const title = window.location.pathname.split("/");
  const selectedItem =
    title[title.length - 1].charAt(0).toUpperCase() +
    title[title.length - 1].slice(1);

  const array: any = [
    { lable: "10", value: 10 },
    { lable: "20", value: 20 },
    { lable: "25", value: 25 },
  ];

  useEffect(() => {
    setUpdatedRows(rows);
  }, [rows]);

  const handleQuantityChange = (event: any, index: number) => {
    const { value } = event.target;
    setUpdatedRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index], quantity: parseInt(value) || 0 };
      return newRows;
    });
    onValueChange(updatedRows);
  };

  const handlePriceChange = (event: any, index: number) => {
    const { value } = event.target;
    setUpdatedRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index], itemPrice: parseInt(value) || 0 };
      return newRows;
    });
    onValueChange(updatedRows);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // "&:last-child td, &:last-child th": {
    //     border: "1px solid red",
    // },
  }));

  useEffect(() => {
    if (onValueChange !== undefined) {
      onValueChange(updatedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValueChange, updatedRows]);

  return (
    <Box className="mobile-table-block" sx={{ mt: 2, backgroundColor: "#fff" }}>
      {updatedRows &&
        rows?.map((row: any, index: any) => (
          <TableContainer
            style={{ margin: "15px 0px", border: "1px solid #E4E3E3" }}
            key={index}
          >
            <Table
              sx={{ minWidth: "100%", backgroundColor: "#FAF9F9" }}
              aria-label="customized table"
              className="specification-tbl"
            >
              <TableBody>
                <React.Fragment key={row.id}>
                  {headCells.map((cell: any) => (
                    <StyledTableRow key={cell.id}>
                      <StyledTableCell component="th" scope="row">
                        {cell.label}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {cell.id === "date" ? (
                          <span>{new Date(row[cell.id]).toLocaleString()}</span>
                        ) : cell.id === "quantity" &&
                          selectedItem === "Product" ? (
                          <TextField
                            type="number"
                            value={updatedRows[index]?.quantity || 0}
                            onChange={(event) =>
                              handleQuantityChange(event, index)
                            }
                            sx={{ width: "100px" }}
                          />
                        ) : cell.id === "itemPrice" &&
                          selectedItem === "Product" ? (
                          <TextField
                            type="number"
                            value={updatedRows[index]?.itemPrice || 0}
                            onChange={(event) =>
                              handlePriceChange(event, index)
                            }
                            sx={{ width: "100px" }}
                          />
                        ) : cell.id === "image" ? (
                          <img
                            src={row[cell.id]}
                            alt="Product"
                            style={{ width: 50, height: 50 }}
                          />
                        ) : (
                          <span>{row[cell.id]}</span>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  {ButtonActions &&
                    ButtonActions.map((action: any, index: any) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {action.label}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            className={`custom-btn-${index}`}
                            onClick={() => action.onClick(row)}
                          >
                            {action.buttonLabel}
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </React.Fragment>
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      {rows && rows?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 5,
          }}
          className="deskPagination"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">Items per page:</Typography>
            <Select
              value={pageSize}
              onChange={(e: any) => onRowsPerPageChange(e, pageSize)}
              variant="outlined"
              size="small"
            >
              {array?.map((size: any) => (
                <MenuItem key={size} value={size.value}>
                  {size.lable}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Pagination
            count={Math.ceil(80 / pageSize)}
            shape="rounded"
            page={pageIs}
            onChange={onChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default MobileTable;
